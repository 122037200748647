module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"YIMBYtown 2024 Conference, Austin, TX, Feb 26-28, 2024","short_name":"YIMBYtown 2024 Conference, Austin, TX, Feb 26-28, 2024","description":"Join us in the vibrant and rapidly evolving city of Austin, TX, February 26-28th for the groundbreaking YIMBYtown 2024 conference! AURA and Farm&City are delighted to host the premiere national forum for conversation and collaboration in the movement for abundant, affordable, and sustainable cities. This year, we are celebrating wins across North America, the expansive \"big tent\" nature of our movement, and the burgeoning YIMBY scene in Texas.","start_url":"/","display":"minimal-ui","icon":"src/content/site-settings/yimbi-favicon-red.png","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"67e055e80c3dd31acf2c1432fe9c6203"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/node_modules/@openeventkit/event-site/src/cms/cms.js","manualInit":true,"enableIdentityWidget":false},
    },{
      plugin: require('../node_modules/@openeventkit/event-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
